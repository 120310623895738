/* tslint:disable */
/* eslint-disable */
/**
 * Cervus CMet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WeightingParams,
    WeightingParamsFromJSON,
    WeightingParamsFromJSONTyped,
    WeightingParamsToJSON,
    WindRose,
    WindRoseFromJSON,
    WindRoseFromJSONTyped,
    WindRoseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {WindRose}
     * @memberof InlineObject
     */
    windRose?: WindRose;
    /**
     * 
     * @type {WeightingParams}
     * @memberof InlineObject
     */
    weightingParams?: WeightingParams;
}

export function InlineObjectFromJSON(json: any): InlineObject {
    return InlineObjectFromJSONTyped(json, false);
}

export function InlineObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'windRose': !exists(json, 'windRose') ? undefined : WindRoseFromJSON(json['windRose']),
        'weightingParams': !exists(json, 'weightingParams') ? undefined : WeightingParamsFromJSON(json['weightingParams']),
    };
}

export function InlineObjectToJSON(value?: InlineObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'windRose': WindRoseToJSON(value.windRose),
        'weightingParams': WeightingParamsToJSON(value.weightingParams),
    };
}


