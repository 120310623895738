



































































































import Vue from "vue";
export default Vue.extend({
  name: "ImprintCard",

  methods: {
    closeImprintDialog(): void {
      this.$emit("closeImprintDialog");
    },
  },
});
