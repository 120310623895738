/* tslint:disable */
/* eslint-disable */
/**
 * Cervus CMet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WindIncidence,
    WindIncidenceFromJSON,
    WindIncidenceFromJSONTyped,
    WindIncidenceToJSON,
} from './';

/**
 * 
 * @export
 * @interface WindRose
 */
export interface WindRose {
    /**
     * 
     * @type {Array<WindIncidence>}
     * @memberof WindRose
     */
    incidences?: Array<WindIncidence>;
    /**
     * 
     * @type {number}
     * @memberof WindRose
     */
    calms: number;
}

export function WindRoseFromJSON(json: any): WindRose {
    return WindRoseFromJSONTyped(json, false);
}

export function WindRoseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WindRose {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incidences': !exists(json, 'incidences') ? undefined : ((json['incidences'] as Array<any>).map(WindIncidenceFromJSON)),
        'calms': json['calms'],
    };
}

export function WindRoseToJSON(value?: WindRose | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incidences': value.incidences === undefined ? undefined : ((value.incidences as Array<any>).map(WindIncidenceToJSON)),
        'calms': value.calms,
    };
}


