























import Vue from "vue";
import PrivacyCard from "./cards/PrivacyCard.vue";
import ImprintCard from "./cards/ImprintCard.vue";

export default Vue.extend({
  name: "Footer",

  components: {
    PrivacyCard,
    ImprintCard,
  },

  data: () => ({
    isImprintOpen: false,
    isPrivacyOpen: false,
    dialogMaxWidth: "800px",
  }),
});
