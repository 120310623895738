var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Navbar'),_c('v-main',[_c('v-expand-transition',[_c('Loader',{attrs:{"showLoader":_vm.showLoader}})],1),_c('v-container',{staticClass:"mt-16"},[_c('v-row',[_c('v-col',[_c('v-slide-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContent),expression:"showContent"}],attrs:{"height":"100%","loading":"true"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.plotCardTitle[_vm.plotIndex])+" "),_c('v-spacer'),(_vm.plotIndex === 0)?_c('v-switch',{staticClass:"mt-1 mb-n5 pr-2",attrs:{"label":"Polarkoordinaten","height":"50%","dense":""},model:{value:(_vm.showPolarPlot),callback:function ($$v) {_vm.showPolarPlot=$$v},expression:"showPolarPlot"}}):_vm._e()],1),_c('v-divider'),_c('v-container',[_c('v-carousel',{attrs:{"hide-delimiters":"","height":"100%"},model:{value:(_vm.plotIndex),callback:function ($$v) {_vm.plotIndex=$$v},expression:"plotIndex"}},[_c('v-carousel-item',[(!_vm.showPolarPlot)?_c('CartesianPlot',{attrs:{"uuid":_vm.plotUuid[0],"xdata":Array.from(
                          Array(this.getInterpolatedCmet.length).keys()
                        ),"ydata":this.getInterpolatedCmet.map(function (item) { return item.value; }),"indexOfMarker":this.getAngleEta.degree,"title":{
                        xaxis: 'Winkel η [°]',
                        yaxis: _vm.plotCardTitle[0].concat(' C<sub>0</sub> [dB]'),
                      },"hoverUnit":"dB"}}):_c('PolarPlot',{attrs:{"uuid":_vm.plotUuid[4],"thetadata":Array.from(
                          Array(this.getInterpolatedCmet.length).keys()
                        ),"rdata":this.getInterpolatedCmet.map(function (item) { return item.value; }),"indexOfMarker":this.getAngleEta.degree,"title":{
                        angularaxis: 'Winkel η [°]',
                        radialaxis: 'C<sub>0</sub> [dB]',
                      },"hoverUnit":"dB"}}),(this.getInterpolatedCmet[0] != null)?_c('v-card',{staticClass:"ma-1 mt-16 text-center"},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" C"),_c('sub',[_vm._v("0")]),_vm._v("("+_vm._s(this.getAngleEta.degree)+"°) = "+_vm._s(this.getInterpolatedCmet[ this.getAngleEta.degree ].value.toFixed(3))+" dB ")])],1):_vm._e()],1),_c('v-carousel-item',[_c('CartesianPlot',{attrs:{"uuid":_vm.plotUuid[1],"xdata":Array.from(
                          Array(
                            this.getInterpolatedWeightingLevels.length
                          ).keys()
                        ),"ydata":this.getInterpolatedWeightingLevels.map(
                          function (item) { return item.value; }
                        ),"indexOfMarker":this.getAngleEta.degree,"title":{
                        xaxis: 'Winkel η [°]',
                        yaxis: _vm.plotCardTitle[1].concat(' G [dB]'),
                      },"hoverUnit":"dB"}}),(
                        this.getInterpolatedWeightingLevels[
                          this.getAngleEta.degree
                        ] != null
                      )?_c('v-card',{staticClass:"ma-1 mt-16 text-center"},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" G("+_vm._s(this.getAngleEta.degree)+"°) = "+_vm._s(this.getInterpolatedWeightingLevels[ this.getAngleEta.degree ].value.toFixed(3))+" dB ")])],1):_vm._e()],1),_c('v-carousel-item',[_c('CartesianPlot',{attrs:{"uuid":_vm.plotUuid[2],"xdata":Array.from(
                          Array(
                            this.getInterpolatedWindIncidence.length
                          ).keys()
                        ),"ydata":this.getInterpolatedWindIncidence.map(
                          function (item) { return item.value; }
                        ),"indexOfMarker":this.getAngleEta.degree,"title":{
                        xaxis: 'Winkel η [°]',
                        yaxis: _vm.plotCardTitle[2].concat(' W [30 %/°]'),
                      },"hoverUnit":"· 30 %/°"}}),(
                        this.getInterpolatedWindIncidence[
                          this.getAngleEta.degree
                        ] != null
                      )?_c('v-card',{staticClass:"ma-1 mt-16 text-center"},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" W("+_vm._s(this.getAngleEta.degree)+"°) = "+_vm._s(this.getInterpolatedWindIncidence[ this.getAngleEta.degree ].value.toFixed(3))+" · 30 %/° ")])],1):_vm._e()],1),_c('v-carousel-item',[_c('CartesianPlot',{attrs:{"uuid":_vm.plotUuid[3],"xdata":Array.from(
                          Array(this.getInterpolatedAirFlow.length).keys()
                        ),"ydata":this.getInterpolatedAirFlow.map(function (item) { return item.value; }),"indexOfMarker":this.getAngleEta.degree,"title":{
                        xaxis: 'Winkel η [°]',
                        yaxis: _vm.plotCardTitle[3].concat(' A [30 %/°]'),
                      },"hoverUnit":"· 30 %/°"}}),(
                        this.getInterpolatedAirFlow[
                          this.getAngleEta.degree
                        ] != null
                      )?_c('v-card',{staticClass:"ma-1 mt-16 text-center"},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" A("+_vm._s(this.getAngleEta.degree)+"°) = "+_vm._s(this.getInterpolatedAirFlow[ this.getAngleEta.degree ].value.toFixed(3))+" · 30 %/° ")])],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-slide-x-reverse-transition',[_c('WeatherParameterCard',{attrs:{"id":"weathercard","showContent":_vm.showContent}})],1)],1)],1)],1),_c('Footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }