






















































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import { WindIncidenceFromJSON } from "../../api/models/index";

export default Vue.extend({
  props: {
    showContent: Boolean,
  },
  created() {
    let windIndicidenceDefaultValue = [
      2.5, 2.9, 6.7, 10.5, 6.6, 5.8, 8.5, 9.5, 14.7, 17.3, 9.3, 4.5,
    ];
    let calmsDefaultValue = 1.2;
    let weightingParamsDefaultAngle = 45;
    let weightingParamsDefaultValue = 3;
    let angleEtaDefaultValue = 60;

    this.windRose = this.getWindRose;
    this.weightingParams = this.getWeightingParams;
    this.angleEta = this.getAngleEta;

    this.indexResolution = this.getIndexResolution;

    for (let i = 0; i < this.windRose.incidences.length; i++) {
      let json = {
        angle: i * this.indexResolution,
        value: windIndicidenceDefaultValue[i],
      };
      this.windRose.incidences[i] = WindIncidenceFromJSON(json);
    }
    this.windRose.calms = calmsDefaultValue;

    this.weightingParams.angle = weightingParamsDefaultAngle;
    this.weightingParams.value = weightingParamsDefaultValue;

    this.angleEta.degree = angleEtaDefaultValue;
  },

  computed: {
    ...mapGetters([
      "getWindRose",
      "getWeightingParams",
      "getAngleEta",
      "getAngleLength",
      "getIndexResolution",
    ]),

    windIncidence_0: {
      get: function (): number {
        return this.windRose.incidences[0].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(0, 1, {
          angle: 0 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_1: {
      get: function (): number {
        return this.windRose.incidences[1].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(1, 1, {
          angle: 1 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_2: {
      get: function (): number {
        return this.windRose.incidences[2].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(2, 1, {
          angle: 2 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_3: {
      get: function (): number {
        return this.windRose.incidences[3].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(3, 1, {
          angle: 3 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_4: {
      get: function (): number {
        return this.windRose.incidences[4].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(4, 1, {
          angle: 4 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_5: {
      get: function (): number {
        return this.windRose.incidences[5].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(5, 1, {
          angle: 5 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_6: {
      get: function (): number {
        return this.windRose.incidences[6].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(6, 1, {
          angle: 6 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_7: {
      get: function (): number {
        return this.windRose.incidences[7].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(7, 1, {
          angle: 7 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_8: {
      get: function (): number {
        return this.windRose.incidences[8].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(8, 1, {
          angle: 8 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_9: {
      get: function (): number {
        return this.windRose.incidences[9].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(9, 1, {
          angle: 9 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_10: {
      get: function (): number {
        return this.windRose.incidences[10].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(10, 1, {
          angle: 10 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windIncidence_11: {
      get: function (): number {
        return this.windRose.incidences[11].value;
      },
      set: function (value: number): void {
        this.windRose.incidences.splice(11, 1, {
          angle: 11 * this.indexResolution,
          value: +value,
        });
        this.$store.dispatch("getCmetFromApi");
      },
    },
    windRoseCalms: {
      get: function (): number {
        return this.windRose.calms;
      },
      set: function (value: number): void {
        Vue.set(this.windRose, "calms", +value);
        this.$store.dispatch("getCmetFromApi");
      },
    },
    weightingParamsValue: {
      get: function (): number {
        return this.weightingParams.value;
      },
      set: function (value: number): void {
        Vue.set(this.weightingParams, "value", +value);
        this.$store.dispatch("getCmetFromApi");
      },
    },
    weightingParamsAngle: {
      get: function (): number {
        return this.weightingParams.angle;
      },
      set: function (value: number): void {
        Vue.set(this.weightingParams, "angle", +value);
        this.$store.dispatch("getCmetFromApi");
      },
    },
    angleEtaProp: {
      get: function (): number {
        return this.angleEta.degree;
      },
      set: function (value: number): void {
        this.angleEta.degree = +value;
      },
    },
  },
  data: () => ({
    indexResolution: 0,
    windRose: Object(),
    weightingParams: Object(),
    angleEta: Object(),

    windUnit: "30 %/°",
    weightingUnit: "dB",
    angleUnit: "°",
  }),
});
