<template>
  <div>
    <!-- Plot -->
    <div :ref="uuid" style="width: 100%; height: 100%" />
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  props: {
    uuid: Number,
    thetadata: Array,
    rdata: Array,
    indexOfMarker: Number,
    hoverUnit: String,
    title: {
      angularaxis: String,
      radialaxis: String,
    },
  },
  mounted() {
    let plotly = Plotly.newPlot(
      this.$refs[this.uuid],
      this.plotData,
      this.plotLayout,
      this.plotConfig
    );

    this.plotLayout.polar.angularaxis.title.text = this.title.angularaxis;
    this.plotLayout.polar.radialaxis.title.text = this.title.radialaxis;

    plotly.then(this.registerEvent);
  },
  computed: {
    plotData: function () {
      let plotDataObject = [
        {
          theta: this.thetadata,
          r: this.rdata,
          line: { color: "#627090" },
          type: "scatterpolar",
          hovertemplate:
            "%{theta:}°, %{r:.3f} " + this.hoverUnit + "<extra></extra>",
        },
        {
          theta: this.thetadata.filter((item, index) => index % 30 == 0),
          r: this.rdata.filter((item, index) => index % 30 == 0),
          mode: "markers",
          marker: { symbol: "circle", size: 10, color: "#DF624D" },
          type: "scatterpolar",
          hoverinfo: "skip",
        },
        {
          theta: [this.indexOfMarker],
          r: [this.rdata[this.indexOfMarker]],
          mode: "markers",
          marker: { symbol: "cross", size: 10, color: "#2E406B" },
          type: "scatterpolar",
          hoverinfo: "skip",
        },
      ];
      return plotDataObject;
    },
    plotLayout: function () {
      let plotLayout = this.plotLayoutBase;
      plotLayout.polar.angularaxis.title.text = this.title.angularaxis;
      plotLayout.polar.radialaxis.title.text = this.title.radialaxis;
      return plotLayout;
    },
  },
  watch: {
    plotData: {
      handler() {
        if (this.$refs[this.uuid] != null) {
          Plotly.react(this.$refs[this.uuid], this.plotData, this.plotLayout);
        }
      },
      deep: true,
    },
  },
  data: () => ({
    plotLayoutBase: {
      autosize: true,
      margin: {
        l: 110,
        r: 75,
        b: 65,
        t: 65,
      },
      showlegend: false,
      hovermode: "closest",
      font: {
        family: "Roboto, sans-serif",
      },
      polar: {
        angularaxis: {
          title: {
            text: String,
          },
          rotation: 90,
          dtick: 30,
        },
        radialaxis: {
          title: {
            text: String,
          },
        },
      },
    },
    plotConfig: {
      displayModeBar: false,
    },
  }),
  methods: {
    registerEvent() {
      this.$refs[this.uuid].on("plotly_click", this.emitClickEvent);
    },
    emitClickEvent(data) {
      this.$store.commit("setAngleEta", data.points[0].theta);
    },
  },
};
</script>
