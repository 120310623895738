import Vue from "vue";
import Vuex from "vuex";
import {
  MeteorologicalCorrection,
  WeightingLevels,
  WindIncidence,
  AirFlow,
  InlineObjectFromJSON,
} from "@/api/models/index";
import { DefaultApi } from "@/api/apis/index";
import getTimeStamp from "@/lib/util";

Vue.use(Vuex);

const ANGLE_LENGTH = 360;
const INDEX_LENGTH = 12;
const INDEX_RESOLUTION = 30;

const apiClient = new DefaultApi();

export default new Vuex.Store({
  state: {
    releaseTag: String("2021.07.1"),
    openInfoDialog: false,

    windRose: {
      incidences: Array<WindIncidence>(INDEX_LENGTH),
      calms: Number(),
    },
    weightingParams: {
      angle: Number(),
      value: Number(),
    },
    angleEta: {
      degree: Number(),
    },

    angleLength: Number(ANGLE_LENGTH),
    indexLength: Number(INDEX_LENGTH),
    indexResolution: Number(INDEX_RESOLUTION),

    interpolatedCmet: Array<MeteorologicalCorrection>(ANGLE_LENGTH),
    interpolatedWeightingLevels: Array<WeightingLevels>(ANGLE_LENGTH),
    interpolatedWindIncidence: Array<WindIncidence>(ANGLE_LENGTH),
    interpolatedAirFlow: Array<AirFlow>(ANGLE_LENGTH),
  },
  getters: {
    getReleaseTag: (state) => state.releaseTag,
    getOpenInfoDialog: (state) => state.openInfoDialog,

    getWindRose: (state) => state.windRose,
    getWeightingParams: (state) => state.weightingParams,
    getAngleEta: (state) => state.angleEta,

    getAngleLength: (state) => state.angleLength,
    getIndexLength: (state) => state.indexLength,
    getIndexResolution: (state) => state.indexResolution,

    getInterpolatedCmet: (state) => state.interpolatedCmet,
    getInterpolatedWeightingLevels: (state) =>
      state.interpolatedWeightingLevels,
    getInterpolatedWindIncidence: (state) => state.interpolatedWindIncidence,
    getInterpolatedAirFlow: (state) => state.interpolatedAirFlow,
  },
  mutations: {
    saveCmetFromApi(state, inlineResponse) {
      if (inlineResponse.interpolatedMeteorologicalCorrection != undefined) {
        state.interpolatedCmet =
          inlineResponse.interpolatedMeteorologicalCorrection;
      }
      if (inlineResponse.weightingFunction != undefined) {
        state.interpolatedWeightingLevels = inlineResponse.weightingFunction;
      }
      if (inlineResponse.interpolatedWindRose != undefined) {
        state.interpolatedWindIncidence = inlineResponse.interpolatedWindRose;
      }
      if (inlineResponse.interpolatedAirFlow != undefined) {
        state.interpolatedAirFlow = inlineResponse.interpolatedAirFlow;
      }
    },

    setAngleEta(state, etaDegree: number) {
      state.angleEta.degree = etaDegree;
    },
    setOpenInfoDialog(state, value: boolean) {
      state.openInfoDialog = value;
    },
  },
  actions: {
    getCmetFromApi() {
      const io = InlineObjectFromJSON({
        windRose: this.state.windRose,
        weightingParams: this.state.weightingParams,
      });

      apiClient
        .cmetPost({
          inlineObject: io,
        })
        .then((inlineResponse) => {
          this.commit("saveCmetFromApi", inlineResponse);
        });
    },
    downloadProjectDataFile() {
      const timeStamp = getTimeStamp();
      let projectDataFileName = "cmet-web_";
      projectDataFileName += timeStamp;
      projectDataFileName += ".json";

      const projectData = JSON.stringify(
        {
          title: "Cervus Consult CMet Web",
          release: this.state.releaseTag,
          timeStamp: timeStamp,
          windRose: this.state.windRose,
          weightingParams: this.state.weightingParams,
        },
        null,
        2
      );

      const downloadElement = document.createElement("a");
      downloadElement.setAttribute(
        "href",
        "data:text/json;charset=utf-8," + encodeURI(projectData)
      );
      downloadElement.setAttribute("download", projectDataFileName);
      downloadElement.style.display = "none";

      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    },
    toggleOpenInfoDialog() {
      this.state.openInfoDialog = !this.state.openInfoDialog;
    },
  },
});
