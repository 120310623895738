




























































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import Navbar from "./components/Navbar.vue";
import Loader from "./components/Loader.vue";
import Footer from "./components/Footer.vue";
import CartesianPlot from "./components/plots/CartesianPlot.vue";
import PolarPlot from "./components/plots/PolarPlot.vue";
import WeatherParameterCard from "./components/cards/WeatherParameterCard.vue";

export default Vue.extend({
  name: "App",

  components: {
    Navbar,
    Loader,
    Footer,
    CartesianPlot,
    PolarPlot,
    WeatherParameterCard,
  },

  mounted() {
    this.$store.dispatch("getCmetFromApi");

    setTimeout(() => {
      this.showLoader = false;
    }, this.startTransition);
    setTimeout(() => {
      this.showContent = true;
    }, this.startTransition + this.startTransitionPause);
  },

  computed: {
    ...mapGetters([
      "getAngleEta",
      "getInterpolatedCmet",
      "getInterpolatedWeightingLevels",
      "getInterpolatedWindIncidence",
      "getInterpolatedAirFlow",
    ]),
  },
  data: () => ({
    startTransition: 1500,
    startTransitionPause: 500,
    showLoader: true,
    showContent: false,

    showPolarPlot: false,
    plotIndex: 0,
    plotCardTitle: [
      "Meteorologische Korrektur",
      "Gewichtungsfunktion",
      "Windrichtungshäufigkeit",
      "Luftströmungshäufigkeit",
    ],
    plotUuid: [1000, 1001, 1002, 1003, 1004],
  }),
});
