























import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  props: {
    showLoader: Boolean,
  },
  computed: {
    ...mapGetters(["getReleaseTag"]),
  },
});
