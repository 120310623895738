<template>
  <div>
    <!-- Plot -->
    <div :ref="uuid" style="width: 100%; height: 100%" />
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  props: {
    uuid: Number,
    xdata: Array,
    ydata: Array,
    indexOfMarker: Number,
    hoverUnit: String,
    title: {
      xaxis: String,
      yaxis: String,
    },
  },
  mounted() {
    let plotly = Plotly.newPlot(
      this.$refs[this.uuid],
      this.plotData,
      this.plotLayout,
      this.plotConfig
    );

    this.plotLayout.xaxis.title = this.title.xaxis;
    this.plotLayout.yaxis.title = this.title.yaxis;

    plotly.then(this.registerEvent);
  },
  computed: {
    plotData: function () {
      let plotDataObject = [
        {
          x: this.xdata,
          y: this.ydata,
          line: { color: "#627090" },
          hovertemplate:
            "%{x:}°, %{y:.3f} " + this.hoverUnit + "<extra></extra>",
        },
        {
          x: this.xdata.filter((item, index) => index % 30 == 0),
          y: this.ydata.filter((item, index) => index % 30 == 0),
          mode: "markers",
          marker: { symbol: "circle", size: 10, color: "#DF624D" },
          hoverinfo: "skip",
        },
        {
          x: [this.indexOfMarker],
          y: [this.ydata[this.indexOfMarker]],
          mode: "marker",
          marker: { symbol: "cross", size: 10, color: "#2E406B" },
          hoverinfo: "skip",
        },
      ];
      return plotDataObject;
    },
    plotLayout: function () {
      let plotLayout = this.plotLayoutBase;
      plotLayout.xaxis.title = this.title.xaxis;
      plotLayout.yaxis.title = this.title.yaxis;
      return plotLayout;
    },
  },
  watch: {
    plotData: {
      handler() {
        if (this.$refs[this.uuid] != null) {
          Plotly.react(this.$refs[this.uuid], this.plotData, this.plotLayout);
        }
      },
      deep: true,
    },
  },
  data: () => ({
    plotLayoutBase: {
      autosize: true,
      margin: {
        l: 110,
        r: 75,
        b: 65,
        t: 65,
      },
      showlegend: false,
      hovermode: "closest",
      font: {
        family: "Roboto, sans-serif",
      },
      xaxis: {
        title: String,
        autorange: true,
        automargin: true,
        tick0: 0,
        dtick: 30,
      },
      yaxis: {
        title: String,
        autorange: true,
        automargin: true,
      },
    },
    plotConfig: {
      displayModeBar: false,
    },
  }),
  methods: {
    registerEvent() {
      this.$refs[this.uuid].on("plotly_click", this.emitClickEvent);
    },
    emitClickEvent(data) {
      this.$store.commit("setAngleEta", data.points[0].x);
    },
  },
};
</script>
