import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/src/locale/de";
import "@mdi/font/css/materialdesignicons.css";
import "../../node_modules/typeface-roboto/index.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#2E406B", // Blau 100 %
        secondary: "#578AD6", // Cyan 100 %
        accent: "#969FB5", // Blau 50 %
        error: "#DF624D", // Rot 75 % alternativ #E99688 Rot 50 %
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
});
