/* tslint:disable */
/* eslint-disable */
/**
 * Cervus CMet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AirFlow,
    AirFlowFromJSON,
    AirFlowFromJSONTyped,
    AirFlowToJSON,
    MeteorologicalCorrection,
    MeteorologicalCorrectionFromJSON,
    MeteorologicalCorrectionFromJSONTyped,
    MeteorologicalCorrectionToJSON,
    WeightingLevels,
    WeightingLevelsFromJSON,
    WeightingLevelsFromJSONTyped,
    WeightingLevelsToJSON,
    WindIncidence,
    WindIncidenceFromJSON,
    WindIncidenceFromJSONTyped,
    WindIncidenceToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<MeteorologicalCorrection>}
     * @memberof InlineResponse200
     */
    interpolatedMeteorologicalCorrection?: Array<MeteorologicalCorrection>;
    /**
     * 
     * @type {Array<WeightingLevels>}
     * @memberof InlineResponse200
     */
    weightingFunction?: Array<WeightingLevels>;
    /**
     * 
     * @type {Array<WindIncidence>}
     * @memberof InlineResponse200
     */
    interpolatedWindRose?: Array<WindIncidence>;
    /**
     * 
     * @type {Array<AirFlow>}
     * @memberof InlineResponse200
     */
    interpolatedAirFlow?: Array<AirFlow>;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interpolatedMeteorologicalCorrection': !exists(json, 'interpolatedMeteorologicalCorrection') ? undefined : ((json['interpolatedMeteorologicalCorrection'] as Array<any>).map(MeteorologicalCorrectionFromJSON)),
        'weightingFunction': !exists(json, 'weightingFunction') ? undefined : ((json['weightingFunction'] as Array<any>).map(WeightingLevelsFromJSON)),
        'interpolatedWindRose': !exists(json, 'interpolatedWindRose') ? undefined : ((json['interpolatedWindRose'] as Array<any>).map(WindIncidenceFromJSON)),
        'interpolatedAirFlow': !exists(json, 'interpolatedAirFlow') ? undefined : ((json['interpolatedAirFlow'] as Array<any>).map(AirFlowFromJSON)),
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interpolatedMeteorologicalCorrection': value.interpolatedMeteorologicalCorrection === undefined ? undefined : ((value.interpolatedMeteorologicalCorrection as Array<any>).map(MeteorologicalCorrectionToJSON)),
        'weightingFunction': value.weightingFunction === undefined ? undefined : ((value.weightingFunction as Array<any>).map(WeightingLevelsToJSON)),
        'interpolatedWindRose': value.interpolatedWindRose === undefined ? undefined : ((value.interpolatedWindRose as Array<any>).map(WindIncidenceToJSON)),
        'interpolatedAirFlow': value.interpolatedAirFlow === undefined ? undefined : ((value.interpolatedAirFlow as Array<any>).map(AirFlowToJSON)),
    };
}


