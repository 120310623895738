function getTimeStamp(): string {
  const date = new Date();

  const days = ("0" + date.getDate().toString()).slice(-2);
  const month = ("0" + (date.getMonth() + 1).toString()).slice(-2);
  const year = date.getFullYear().toString();

  const hour = ("0" + date.getHours().toString()).slice(-2);
  const minute = ("0" + date.getMinutes().toString()).slice(-2);
  const second = ("0" + date.getSeconds().toString()).slice(-2);

  return year + month + days + "T" + hour + minute + second;
}

export default getTimeStamp;
