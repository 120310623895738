















































































































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Navbar",
  data: () => ({
    isNavOpen: false,
    isAlertOpen: false,

    contextMenu: [
      {
        name: "Projekt speichern",
        icon: "mdi-content-save",
        action: "downloadProjectDataFile",
        divider: false,
      },
      {
        name: "Projekt hochladen",
        icon: "mdi-cloud-upload",
        action: "notimplementedyet",
        divider: true,
      },
      {
        name: "Bericht herunterladen",
        icon: "mdi-file-download",
        action: "notimplementedyet",
        divider: true,
      },
      {
        name: "Informationen",
        icon: "mdi-information",
        action: "toggleOpenInfoDialog",
        divider: true,
      },
    ],
  }),
  computed: {
    ...mapGetters(["getReleaseTag", "getOpenInfoDialog"]),

    isInfoDialogOpen: {
      get: function () {
        return this.getOpenInfoDialog;
      },
      set: function (value) {
        this.$store.commit("setOpenInfoDialog", value);
      },
    },
  },
  methods: {
    dispatchStoreAction(action: string) {
      if (action != "notimplementedyet") {
        this.$store.dispatch(action);
      } else {
        this.isAlertOpen = true;
      }
    },
  },
});
