






















































import Vue from "vue";
export default Vue.extend({
  name: "PrivacyCard",

  methods: {
    closePrivacyDialog(): void {
      this.$emit("closePrivacyDialog");
    },
  },
});
